/*=========================================================================================
  File Name: moduleHospitalMutations.js
  Description: Hospital Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default {
  ADD_ITEM(state, item) {
    state.hospitals.unshift(item)
  },
  SET_Hospital(state, hospitals) {
    debugger
    state.hospitals = hospitals
  },
  SET_SearchHospital(state, hospitals)
  {
    state.searchHospitals = hospitals

  },
  SET_SimpleSearchHospital(state, hospitals)
  {
    debugger
    state.searchSimpleHospitals = hospitals

  },
  SET_SearchHospitalSurgeries(state, surgeries)
  {
    state.searchSurgeries = surgeries

  },

  SET_SearchHospitalDoctors(state, hospitalDoctors)
  {
    state.hospitalDoctors=hospitalDoctors;
  },
  SET_Doctors(state, Doctors)
  {
    state.Doctors=Doctors;
  },
  SET_HospitalSpecialties(state,hospitalSpecialties)
  {
    var specialities=[];
    hospitalSpecialties.forEach(element => {
      specialities.push(element.Specialty);
    });
    state.hospitalSpecialties=specialities;
  },

  UPDATE_Hospital(state, Hospital) {
    const HospitalIndex = state.hospitals.findIndex((p) => p.ID == Hospital.ID)
    Object.assign(state.hospitals[HospitalIndex], Hospital)
  },
  
  REMOVE_ITEM(state, itemId) {
      const ItemIndex = state.hospitals.findIndex((p) => p.ID == itemId)
      state.hospitals.splice(ItemIndex, 1)
  },

}

<!-- =========================================================================================
    File Name: Login.vue
    Description: Login Page
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>

  <div>
    <div>
      <b-card>
        <b-row>
          <b-col cols="12" md="6">
            <b-form-group :label="$t('ChooseMainSpecialty')" label-for="ChooseMainSpecialty">
              <v-select label="SpecialtyName" v-model="MainSpecialty"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="surgeryCategories" :reduce="val => val"
                input-id="ChooseMainSpecialty" @input="selectSurgCategory(MainSpecialty, 1); select(MainSpecialty);"
                v-on:click="select(MainSpecialty); selectSurgCategory(supSpecialty, 2);" />
            </b-form-group>
          </b-col>

          <b-col cols="12" md="6">
            <b-form-group :label="$t('ChooseSubSpecialty')" label-for="ChooseSubSpecialty">
              <v-select label="SpecialtyName" v-model="supSpecialty" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="surgeriesByCat" :reduce="val => val" input-id="ChooseSubSpecialty"
                @input="selectSurgCategory(supSpecialty, 2); select(supSpecialty);"
                v-on:click="select(supSpecialty); selectSurgCategory(supSpecialty, 2);" />
            </b-form-group>
          </b-col>
        </b-row>
      </b-card>
    </div>

    <p class="warning" style="text-align:center"> {{ $t("saveNote") }}</p>

    <b-row>
      <b-col cols="12" md="5">

        <vs-card style="height: 40vh; overflow-y: scroll">
          <h3 class="ActiveCardHeader primaryBackColor">{{ $t("Doctors") }}</h3>
          <div class="m-1">
            <div class="Active mt-1 ml-0" v-if="searchDoctorModel.SpecialtyID > 0">
              <feather-icon style="width: 15px" icon="PlusCircleIcon" class="cursor-pointer"></feather-icon>
              <u @click="ShowCreateDoctorProfile = true" class="mt-0 ml-2">
                {{ $t("CreateNewDoctorProfile") }}
              </u>
            </div>
            <vs-input v-model="search.DoctorName" :placeholder="$t('Search')" class="w-full mt-1" name="search" />
            <div v-for="item in filterDoctor" :key="item.ID" class="shadow-md w-full mt-2">
              <b-row>
                <b-form-checkbox v-model="item.IsSelected" @input="ChangeSpecialitySelect(item)"
                  :disabled="(item.CantChecked == true || !(searchDoctorModel.SpecialtyID > 0))"></b-form-checkbox>
                <span v-if="item.FullName"> {{ item.FullName }} </span>
                <span v-else> {{ item.Name }} </span>
              </b-row>
            </div>
          </div>
        </vs-card>
      </b-col>
      <b-col cols="12" md="1" class="mt-5">

        <b-button :disabled="DoctorSelected.length == 0" size="small" @click="AddHospitalDoctor(DoctorSelected)"
          :variant="DoctorSelected.length == 0 ? 'white' : 'primary'"
          style="border-radius: 20%; opacity: 1; border-color: black;border: 2px solid #1F1F1F">
          <feather-icon :icon="!$vs.rtl ? 'ArrowRightIcon' : 'ArrowLeftIcon'" class="cursor-pointer"></feather-icon>
        </b-button>
        <br />

        <b-button :disabled="DoctorUnSelected.length == 0" size="small" @click="DeleteDoctorFromList(DoctorUnSelected)"
          class="mt-1" :variant="DoctorUnSelected.length == 0 ? 'white' : 'primary'"
          style="border-radius: 20%; opacity: 1; border-color: black;border: 2px solid #1F1F1F">
          <feather-icon :icon="!$vs.rtl ? 'ArrowLeftIcon' : 'ArrowRightIcon'" class="cursor-pointer"></feather-icon>
        </b-button>


      </b-col>
      <b-col cols="12" md="6">
        <vs-card class="" style="height: 40vh; overflow-y: scroll">
          <h3 class="ActiveCardHeader secondaryBackColor">{{ $t("AllAddedDoctors") }}</h3>
          <div v-for="(item, index) in HospitalDoctors" :data="item.Specialty" :key="index.ID"
            v-if="item.IsActive != false" class=" mt-2 m-1">
            <b-row>
              <b-form-checkbox   :disabled="(item.CantChecked == true || !(searchDoctorModel.SpecialtyID > 0))"
                 v-model="item.IsSelected" @input="ChangeSpecialityUnSelect(item)"></b-form-checkbox>
              <span v-if="item.Doctor"> {{ item.Doctor.FullName }} / {{ item.HospitalSpecialty.Specialty.Name }}
              </span>
              <span v-else> {{ item.Doctor.Name }} </span>
            </b-row>
          </div>

        </vs-card>
      </b-col>

    </b-row>

    <div>
      <b-button class="mb-1 mb-sm-0 mr-0 mr-sm-1" variant="primary" @click="SaveDoctors()">
        {{ $t('Submit') }}
      </b-button>

      <b-button type="border" color="danger" class="mb-1 mb-sm-0 mr-0 mr-sm-1" @click="$router.go(-1)">
        {{ $t('back') }}
      </b-button>
    </div>

    <vs-popup title="" :active.sync="ShowCreateDoctorProfile">
      <CreateDoctorProfile v-if="ShowCreateDoctorProfile" :searchDoctorModel="searchDoctorModel"
        @closePop="ShowCreateDoctorProfile = false"></CreateDoctorProfile>
    </vs-popup>
  </div>

</template>

<script>
import moduleDoctor from "@/store/doctor/moduleDoctor.js";
import moduleHospital from "@/store/hospital/moduleHospital.js";
import moduleSpecialty from "@/store/settings/specialty/moduleSpecialty.js";
import CreateDoctorProfile from "@/views/hospital/CreateDoctorProfile.vue";
import { domain, mainSetting } from "@/gloabelConstant.js";
import { BNavItemDropdown, FormGroupPlugin } from 'bootstrap-vue'

import {
  BButton,
  BCard,
  BCardBody,
  BCardFooter,
  BCardHeader,
  BCardText,
  BCol,
  BImg,
  BLink,
  BRow,
  BFormCheckbox,
  BFormGroup,


} from 'bootstrap-vue';
import formsTables from "@/router/routes/forms-tables";

export default {
  data() {
    return {
      EnglishLan: false,
      isAll: false,
      ShowCreateDoctorProfile: false,
      baseURL: domain,
      UploadRequestHeader: mainSetting.UploadRequestHeader,
      uploadUrl: domain + "api/Common/UploadFile",
      Model: {},
      search: {},
      MainSpecialty: [],
      supSpecialty: [],
      searchDoctorModel: {

      },
      selectedSpecialityFilter: {},
      HospitalDoctors: [],
      SecondActiveIndex: null,
      ActiveIndex: null,
      MainsurgeriesByCat: [],
      surgeriesByCat: [],
      surgeryCategories: [],
      DoctorSelected: [],
      DoctorUnSelected: [],
    };
  },
  components: {
    CreateDoctorProfile,
    BButton,
    BCard,
    BCardBody,
    BCardFooter,
    BCardHeader,
    BCardText,
    BCol,
    BImg,
    BLink,
    BRow,
    BFormCheckbox,
    FormGroupPlugin,
    BFormGroup,

  },
  computed: {
    searchDoctors() {
      return this.$store.state.DoctorList.searchDoctors;
    },
    filterDoctor() {
      if (this.isAll == true) return null;
      debugger;
      if (this.search.DoctorName) {
        var vm = this;
        return this.searchDoctors.filter(function (obj) {
          return (
            obj.Name.toLowerCase().indexOf(vm.search.DoctorName.toLowerCase()) >
            -1
          );
        });
      } else return this.searchDoctors;
    },
  },
  methods: {
    ChangeSpecialitySelect(Doctor) {
      if (Doctor.IsSelected == true) {
        this.DoctorSelected.push(Doctor)

      }
      else {
        var index = this.DoctorSelected.findIndex(
          (obj) => obj.ID == Doctor.ID
        );
        this.DoctorSelected.splice(index, 1);

      }
    },
    gofromSpecialityToSpecialitySelect() {
      debugger
      this.DoctorSelected.forEach(doc => {
        var hospitalDoctor = {};
        hospitalDoctor.Doctor = {};
        hospitalDoctor.Doctor.Name = doc.Name;
        hospitalDoctor.Doctor.ID = doc.ID;
        hospitalDoctor.DoctorID = doc.ID;
        hospitalDoctor.IsActive = true;
        hospitalDoctor.HospitalSpecialtyID =
          this.searchDoctorModel.HospitalSpecialityID;
        hospitalDoctor.HospitalID = this.$store.state.AppActiveUser.Hospital.ID;
        this.HospitalDoctors.push(hospitalDoctor);
        debugger;
        var index = this.HospitalDoctors.findIndex(
          (obj) => obj.DoctorID == doc.ID
        );
        if (index == -1) {
          this.HospitalDoctors.push(spec);
        } else {
          this.HospitalDoctors.splice(index, 1);
        }

      });
      this.DoctorSelected = [];

    },
    ChangeSpecialityUnSelect(doctor) {
      debugger;
      if (doctor.IsSelected == true) {
        this.DoctorUnSelected.push(doctor)
      }
      else {
        var index = this.DoctorUnSelected.findIndex(
          (obj) => obj.DoctorID == doctor.DoctorID
        );
        this.DoctorUnSelected.splice(index, 1);

      }

    },
    DeleteDoctorFromList(item) {
      debugger;
      for (i = 0; item.length > 0; i++) {
        var index = this.HospitalDoctors.findIndex((x) => x.DoctorID == item[i].DoctorID
          && x.HospitalSpecialtyID == this.searchDoctorModel.HospitalSpecialityID);
          if (index > -1){
            this.HospitalDoctors.splice(index, 1);
        this.DoctorUnSelected = [];
        this.SaveDoctors();
          }
      }

    },

    ChangeDoctorUnSelect(Doctor) {
      debugger;
      if (Doctor.IsSelected == true) {
        this.DoctorUnSelected.push()

      }
      else {
        var index = this.DoctorUnSelected.findIndex(
          (obj) => obj.DoctorID == Doctor.DoctorID
        );
        this.DoctorUnSelected.splice(index, 1);

      }

    },

    MakeActive(index) {
      this.SecondActiveIndex = index;
    },
    selectSurgCategory(data, index) {
      debugger;
      this.ActiveIndex = index;
      this.SecondActiveIndex = null;
      if (data != null) {
        this.surgeriesByCat = this.MainsurgeriesByCat.filter(
          (ob) => ob.Specialty.ParentSpecialtyID == data.Specialty.ID
        );
        this.isAll = false;
      } else {
        this.surgeriesByCat = this.MainsurgeriesByCat.filter(
          (ob) => ob.Specialty.ParentSpecialtyID
        );
        this.isAll = true;
      }
    },

    searchDoctor() {
      this.searchDoctorModel.SmallSearch = true;
      this.$store
        .dispatch("DoctorList/SearchDoctors", this.searchDoctorModel)
        .then(() => {
          debugger;

          this.HospitalDoctors.forEach((element) => {
            debugger;
            var index = this.searchDoctors.findIndex(
              (x) => x.ID == element.DoctorID && element.HospitalSpecialtyID == this.searchDoctorModel.HospitalSpecialityID
            );
            if (index != -1) {
              this.searchDoctors[index].IsSelected = true;
            }
          });
        });
    },
    select(item) {
      debugger;
      this.selectedSpecialityFilter = item;
      this.searchDoctorModel.SpecialtyID = item.SpecialtyID;
      this.searchDoctorModel.HospitalSpecialityID = item.ID;
      this.selectedChiledSpecialityFilter = {};
      this.isAll = false;
      this.searchDoctor();
    },
    searchHospitalSpecialty() {
      debugger;
      this.$store
        .dispatch("HospitalList/SearchHospitalSpecialtys", {
          HospitalID: this.$store.state.AppActiveUser.Hospital.ID,
        })
        .then((res) => {
          this.surgeryCategories = res.data.Data.filter(
            (x) => x.Specialty.ParentSpecialtyID == null
          );
          this.MainsurgeriesByCat = res.data.Data.filter(
            (x) => x.Specialty.ParentSpecialtyID != null
          );
          this.surgeriesByCat = this.MainsurgeriesByCat;
        });
    },

    back() {
      this.$router.go(-1);
    },
    DoctorChecked(item) {
      debugger;
      if (item.IsSelected == true) {
        this.AddHospitalDoctor(item);
      } else {
        this.DeleteHospitalDoctor(item);
      }
    },
    DeleteHospitalDoctor(item) {
      debugger;
      var index = this.HospitalDoctors.findIndex((x) => x.DoctorID == item.ID
        && x.HospitalSpecialtyID == this.searchDoctorModel.HospitalSpecialityID);
      this.HospitalDoctors.splice(index, 1);
      this.SaveDoctors();
    },
    AddHospitalDoctor(item) {
      debugger;
      for (i = 0; item.length > 0; i++) {
        var index = this.HospitalDoctors.findIndex((x) => x.DoctorID == item[i].ID);
        if (index >= 0) {
          //alert
        } else {
          var hospitalDoctor = {};
          hospitalDoctor.Doctor = {};
          hospitalDoctor.Doctor.Name = item[i].NameEN;
          hospitalDoctor.Doctor.ID = item[i].ID;
          hospitalDoctor.DoctorID = item[i].ID;
          hospitalDoctor.IsActive = true;
          hospitalDoctor.HospitalSpecialtyID =
            this.searchDoctorModel.HospitalSpecialityID;
          hospitalDoctor.HospitalID = this.$store.state.AppActiveUser.Hospital.ID;
          this.HospitalDoctors.push(hospitalDoctor);

          this.SaveDoctors();
          this.DoctorSelected = [];
        }
      }
    },
    SaveDoctors() {
      this.$vs.loading();

      debugger;
      if (this.HospitalDoctors.length > 0) {
        this.$store
          .dispatch("HospitalList/SaveHospitalDoctors", this.HospitalDoctors)
          .then((res) => {
            if (res.status == 200) {
              this.$vs.loading.close();
              window.showSuccess();
              this.$vs.loading();
              //  this.back();
            }
            this.$vs.loading.close();
            this.$vs.loading();
          });
      } else {
        this.$store
          .dispatch(
            "HospitalList/DeleteOldHospitalDoctors",
            this.$store.state.AppActiveUser.Hospital.ID
          )
          .then((res) => {
            if (res.status == 200) {
              this.$vs.loading.close();
              window.showSuccess();
              this.$vs.loading();
              //  this.back();
            }
            this.$vs.loading.close();
          });
      }
    },
  },
  created() {
    this.EnglishLan =
      localStorage.getItem("SaveLang") == "en" ||
        localStorage.getItem("SaveLang") == null
        ? true
        : false;

    if (!moduleDoctor.isRegistered) {
      this.$store.registerModule("DoctorList", moduleDoctor);
      moduleDoctor.isRegistered = true;
    }
    if (!moduleSpecialty.isRegistered) {
      this.$store.registerModule("SpecialtyList", moduleSpecialty);
      moduleSpecialty.isRegistered = true;
    }
    if (!moduleHospital.isRegistered) {
      this.$store.registerModule("HospitalList", moduleHospital);
      moduleHospital.isRegistered = true;
    }

    this.$vs.loading();

    this.searchHospitalSpecialty();
    this.searchDoctorModel.CountryID = this.$store.state.AppActiveUser.Hospital.CountryID;
    debugger;
    this.$store
      .dispatch(
        "HospitalList/GetHospitalDoctors",
        this.$store.state.AppActiveUser.Hospital.ID
      )
      .then((res) => {
        this.HospitalDoctors = res.data.Data;
        this.$vs.loading.close();
      });
  },
};
</script>

<style >
.ActiveCardHeader {
  color: white;
  padding: 10px;
  border-radius: 5px;
  width: 100%;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.textFont {
  font-size: 16px !important;
  white-space: nowrap;
  text-align: center !important;
}

#custumcard .vs-card--content {
  padding: unset;
}

#customRow .vx-col {
  padding: unset;
}

.Active {
  color: #2753D9;
  padding: 0px;
  border-radius: 12px;
}

.InActive {
  padding: 0px;
  border-radius: 12px;
}

p {
  font-size: medium;
}
</style>
<style scoped>
.RoundedCurve {
  border-radius: 20px;
  background: #355a85;
}
</style>
/*=========================================================================================
  File Name: moduleSpecialtyMutations.js
  Description: Specialty Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default {
  ADD_ITEM(state, item) {
    state.specialties.unshift(item)
  },
  SET_Specialty(state, specialties) {
    state.specialties = specialties
  },
  SET_ChildSpecialty(state, specialties)
  {
    state.childSpecialties = specialties
  },
  SET_ParentSpecialty(state, specialties)
  {
    state.parentSpecialties = specialties
  },
  UPDATE_Specialty(state, Specialty) {
    const SpecialtyIndex = state.specialties.findIndex((p) => p.ID == Specialty.ID)
    Object.assign(state.specialties[SpecialtyIndex], Specialty)
},
REMOVE_ITEM(state, itemId) {
    const ItemIndex = state.specialties.findIndex((p) => p.ID == itemId)
    state.specialties.splice(ItemIndex, 1)
},
}
